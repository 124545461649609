<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="ListIcon" size="30" class="mr-50" />
                {{modeloId}}
                {{ modeloId ? 'Editar' : 'Novo' }}  Modelo
              </h1>
              <!-- <h4 class="text-white">Inicie uma Aplicação de Jogo:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="mb-2">
          <feather-icon icon="CheckCircleIcon" size="30" class="mr-50" />
          Selecionar e ordenar itens
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- draggable -->
        <draggable
          v-model="list"
          class="list-group list-group-flush cursor-move"
          tag="ul"
        >
          <transition-group type="transition" name="flip-list">
            <b-list-group-item
              v-for="item in list"
              :key="item.name"
              tag="li"
            >
              <div class="d-flex">
                <!-- secondary -->
                <b-row>
                  <b-col lg="1">
                    <feather-icon icon="MaximizeIcon" size="20" class="mr-50" />
                  </b-col>
                  <b-col>
                    <div class="ml-25">
                      <!-- <b-card-text class="font-weight-bold mb-0">
                {{ item.name }}
              </b-card-text> -->
                      <h5>{{ item.name }}</h5>
                    </div>
                  </b-col>
                  <div class="check-right">
                    <b-form-checkbox
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      v-model="form[item.model]"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                </b-row>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2 class="mt-3">
          <feather-icon icon="SettingsIcon" size="30" class="mr-50" />
          Configurar Modelo
        </h2>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-card> 
           <b-row>
    <b-col
      
      class="mb-1"
    >

      <!-- basic -->
      <b-form-group
        label="Novo Modelo"
        label-for="basicInput"
      >
        <b-form-input
          id="basicInput"
          placeholder="Nome do Modelo"
        />
      </b-form-group>
      <div>
    <label for="demo-sb">Pontuação</label>
    <b-form-spinbutton
      id="demo-sb"
      v-model="form.score"
      min="1"
      max="100"
    />
    
    <b-alert
      class="mb-1"
      show
      variant="success"
      
    >
      <!-- <div class="alert-body">
        <span>The </span>
        <code>ArrowUp</code>
        <span> and </span>
        <code>ArrowDown</code>
        <span> keys can be used to increment or decrement the value.</span>
      </div> -->
    </b-alert>
  </div>
   <div>
    <label for="textarea-default">Instruções</label>
    <b-form-textarea
      id="textarea-default"
      placeholder="Comentários sobre o novo modelo"
      rows="3"
    />
  </div>
  
    </b-col>

    <b-col
      md="6"
      xl="4"
      class="mb-1"
    >
    
      
    </b-col>
     <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Salvar Modelo
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Limpar
        </b-button>
      </b-col>
    
  </b-row>
  
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BMedia,
  BButton,
  BCalendar,
  BTime,
  BForm,
  BFormTimepicker,
  BFormTextarea,
  BListGroupItem,
  BAvatar,
  BFormCheckbox,
  BFormSpinbutton, 
  BAlert,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import patientService from '@/services/patientService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { hideSpinner, showSpinner } from '@/services/spinService'
import { cloneObject } from '@/@core/utils/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import authService from '@/services/authService'

import { required, email, url } from '@validations'
import auth from '@/router/routes/auth'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BCardText,
    BButton,
    BImg,
    BMedia,
    BCalendar,
    BTime,
    BFormTimepicker,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    draggable,
    BListGroupItem,
    BAvatar,
    BFormCheckbox,
    BFormSpinbutton,
    BAlert,
    
    
  },  
  props: ['modeloId'],
  data() {
    return {
      form: {
        hasQuiz: null,
        score: 0,
      },
      list: [
        {
          name: 'Quiz',
          model: 'hasQuiz',
        },
        {
          name: 'Upload de Foto',
          model: 'hasUploadPhoto',
        },
        {
          name: 'Upload de Vídeos',
          model: 'hasUploadVideo',
        },
        {
          name: 'Upload de PDF',
          model: 'hasUploadPdf',
        },
        {
          name: 'Avaliação por estrelas',
          model: 'hasStarsAvaliation',
        
        },
      ],
    }
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s;
}
</style>
