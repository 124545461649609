/* eslint-disable import/prefer-default-export */
export const apiUtils = {
  objectToQueryParams(obj) {
    if (!obj) return ''
    const params = Object.keys(obj)
      .map(key => `${key}=${params[key]}`)
      .join('&')

    return params
  },
}
