var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ListIcon",
      "size": "30"
    }
  }), _vm._v(" " + _vm._s(_vm.modeloId) + " " + _vm._s(_vm.modeloId ? 'Editar' : 'Novo') + " Modelo ")], 1)])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('h2', {
    staticClass: "mb-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "CheckCircleIcon",
      "size": "30"
    }
  }), _vm._v(" Selecionar e ordenar itens ")], 1)])], 1), _c('b-row', [_c('b-col', [_c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "tag": "ul"
    },
    model: {
      value: _vm.list,
      callback: function callback($$v) {
        _vm.list = $$v;
      },
      expression: "list"
    }
  }, [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.list, function (item) {
    return _c('b-list-group-item', {
      key: item.name,
      attrs: {
        "tag": "li"
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "lg": "1"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "MaximizeIcon",
        "size": "20"
      }
    })], 1), _c('b-col', [_c('div', {
      staticClass: "ml-25"
    }, [_c('h5', [_vm._v(_vm._s(item.name))])])]), _c('div', {
      staticClass: "check-right"
    }, [_c('b-form-checkbox', {
      staticClass: "custom-control-primary",
      attrs: {
        "checked": "true",
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: _vm.form[item.model],
        callback: function callback($$v) {
          _vm.$set(_vm.form, item.model, $$v);
        },
        expression: "form[item.model]"
      }
    }, [_c('span', {
      staticClass: "switch-icon-left"
    }, [_c('feather-icon', {
      attrs: {
        "icon": "CheckIcon"
      }
    })], 1), _c('span', {
      staticClass: "switch-icon-right"
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)])], 1)], 1)], 1)]);
  }), 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('h2', {
    staticClass: "mt-3"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SettingsIcon",
      "size": "30"
    }
  }), _vm._v(" Configurar Modelo ")], 1)])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "mb-1"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Novo Modelo",
      "label-for": "basicInput"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "basicInput",
      "placeholder": "Nome do Modelo"
    }
  })], 1), _c('div', [_c('label', {
    attrs: {
      "for": "demo-sb"
    }
  }, [_vm._v("Pontuação")]), _c('b-form-spinbutton', {
    attrs: {
      "id": "demo-sb",
      "min": "1",
      "max": "100"
    },
    model: {
      value: _vm.form.score,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "score", $$v);
      },
      expression: "form.score"
    }
  }), _c('b-alert', {
    staticClass: "mb-1",
    attrs: {
      "show": "",
      "variant": "success"
    }
  })], 1), _c('div', [_c('label', {
    attrs: {
      "for": "textarea-default"
    }
  }, [_vm._v("Instruções")]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-default",
      "placeholder": "Comentários sobre o novo modelo",
      "rows": "3"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "6",
      "xl": "4"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Salvar Modelo ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Limpar ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }